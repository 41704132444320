<template>
  <div class="autocomplete-box">
    <v-autocomplete
      v-model="selectedItem"
      return-object
      :items="items"
      :item-text="itemText"
      no-data-text="Не знайдено"
      :attach="appendTo"
      disable-lookup
      :menu-props="{ closeOnClick: true }"
      @input="$emit('select')"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @keydown.enter="$event.target.blur()"
    >
    </v-autocomplete>
    <InputError v-if="error" :error-txt="errorTxt" is-show-icon />
  </div>
</template>

<script>
import InputError from '@/elements/Errors/InputError.vue';

export default {
  components: {
    InputError,
  },
  props: {
    value: {
      required: true,
      validator: (prop) => typeof prop === 'object' || prop === null,
    },
    items: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      required: true,
    },
    appendTo: {
      type: String,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTxt: {
      type: String,
      default: '',
    },
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
<style lang="sass">
.autocomplete-box
  position: relative

.autocomplete-box .theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.autocomplete-box .v-text-field.v-input--is-focused > .v-input__control > .v-input__slot:after
  display: none

.v-autocomplete .v-input__icon
  display: flex
  align-items: center
  justify-content: center

.v-input__append-inner
  display: flex
  align-items: center
  justify-content: center

.v-autocomplete.v-select .v-input__icon:before
  background-image: url('/images/select-caret.svg')
  content: ''
  width: 16px
  height: 10px
  transition: var(--transition-speed)

  @media screen and (max-width: 600px)
    background-image: url('/images/select-caret-small.svg')
    width: 10px
    height: 6px

.v-autocomplete.v-select--is-menu-active .v-input__icon:before
  transform: rotate(180deg)

.v-autocomplete .v-input__slot
  background-color: var(--primary-color-white)
  border-radius: var(--primary-border-radius)
  border: 1px solid var(--primary-color-dark-grey-1)
  border-radius: var(--primary-border-radius)!important
  width: inherit
  height: 64px
  padding: 20px
  font-size: 18px
  font-weight: 600
  color: var(--primary-color-dark-blue)
  margin-bottom: 0
  transition: none

.v-autocomplete .v-input__slot:hover
  background-color: rgba(40, 59, 89, 0.02)
  border: 1px solid var(--primary-color-dark-blue)

.autocomplete-box .v-text-field.v-input--is-focused .v-input__slot
  background-color: rgba(40, 59, 89, 0.02)
  border: 2px solid var(--primary-color-dark-blue)

.v-autocomplete.v-input
  padding-top: 0
  margin-top: 0

.autocomplete-box .v-select:not(.v-text-field--single-line):not(.v-text-field--outlined) .v-select__slot > input
  color: var(--primary-color-dark-blue)
  text-transform: uppercase

.v-autocomplete .v-text-field__details
  display: none

.autocomplete-box .v-list .theme--light.v-list-item:not(.v-list-item--disabled)
  color: var(--primary-color-dark-blue)!important
  font-weight: 500
  padding: 0 16px

.autocomplete-box .v-list .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled):hover
  color: var(--secondary-color-dark-blue)!important

.theme--light.v-list-item.v-list-item--active::before
  opacity: 1!important
  background-color: transparent

.autocomplete-box .v-menu__content
  box-shadow: 0px 40px 50px 4px rgba(38, 55, 76, 0.1)
  border: 1px solid var(--primary-color-dark-grey-1)
  border-radius: var(--primary-border-radius)!important

.autocomplete-box .v-menu__content::-webkit-scrollbar
    width: 5px
    background-color: #EEF1F4
    border-radius: 5px
    height: 200px

.autocomplete-box .v-menu__content::-webkit-scrollbar-thumb
    background: var(--primary-color-dark-grey-1)
    border-radius: 5px

.v-autocomplete .v-list
  padding: 0

.autocomplete-box .v-list-item
  min-height: 100%!important

.autocomplete-box .v-list-item__title
  font-size: 18px!important
  font-weight: 500
  text-align: left

.autocomplete-box .v-list-item .v-list-item__content
  padding: 10px 0

.autocomplete-box .v-list-item--active .v-list-item__content
  position: relative

.autocomplete-box .v-list-item--active .v-list-item__content:before
  background-image: url('/images/check.svg')
  position: absolute
  content: ''
  top: 50%
  right: 0
  width: 18px
  height: 14px
  transform: translateY(-50%)
</style>
